import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import useSnackbar from '@hook/useSnackbar'
import Text from '@component/Text/Text'
import {apiDeleteLeader} from '@api/copy/leader/delete/deleteLeader'
import img_stop_copyleader from '@image/img_stop_copyleader.png'
import img_stop_copyleader_dark from '@image/img_stop_copyleader_dark.png'
import WizImage from '@component/images/WizImage'
import WizContentModal from '@component/modal/WizContentModal'
import TagText from '@component/Text/TagText'
import useQueryGetLeaderInfo from '@hook/query/portfolio/leaderfolio/useQueryGetLeaderInfo'
import useDark from '@hook/useDark'
import {addErrorLog, ERROR_LOG} from '@constant/log'

interface IProps {
    id: string
    refreshCallback?: () => void
}

const DeleteLeaderModal = ({id, refreshCallback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {data: leaderInfo} = useQueryGetLeaderInfo(id)
    const {isRenderDark} = useDark()
    const {showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <WizContentModal size={'600'} title={t('modal.deleteLeader.title')} ref={modalRef}>
            <WizImage
                src={isRenderDark ? img_stop_copyleader_dark : img_stop_copyleader}
                containerClassName={'mt-[30px] w-[150px] mb-[10px] mx-auto'}
            />
            <TagText className={'text-body1 text-gray02 dark:text-dark_gray02 text-center'}>
                {t('modal.deleteLeader.desc')}
            </TagText>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 text-center pt-[20px] pb-[10px]'}>
                {t('modal.deleteLeader.warningCopier', {count: leaderInfo?.copiers})}
            </Text>
            {/*{leaderInfo?.copiers > 0 && (*/}
            {/*    <>*/}
            {/*        <Text className={'text-body2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>*/}
            {/*            {t('modal.deleteLeader.warningCopier', {count: leaderInfo?.copiers})}*/}
            {/*        </Text>*/}
            {/*    </>*/}
            {/*)}*/}

            <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                <WizButton
                    text={t('modal.deleteLeader.cancel')}
                    buttonType={'border'}
                    onClick={() => modalRef.current.close()}
                />
                <WizButton
                    text={t('modal.deleteLeader.delete')}
                    buttonType={'red'}
                    onClick={async () => {
                        const {data, error} = await apiDeleteLeader(id)
                        if (data) {
                            modalRef.current.close()
                            refreshCallback && refreshCallback()
                        } else if (error) {
                            addErrorLog(ERROR_LOG.copyLeader.stopFail, {error})
                            showErrorSnackbar(error)
                        }
                    }}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(DeleteLeaderModal)
