import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import Text from '@component/Text/Text'
import img_delete_copier from '@image/img_deleteCopier.png'
import img_delete_copier_dark from '@image/img_deleteCopier_dark.png'
import WizImage from '@component/images/WizImage'
import Spinner from '@component/Spinner/Spinner'
import WizContentModal from '@component/modal/WizContentModal'
import WizRadioButton from '@component/buttons/WizRadioButton'
import useDark from '@hook/useDark'
import {apiPostPausedCopier} from '@api/copy/copier/pausedCopier'
import {addErrorLog, ERROR_LOG} from '@constant/log'
import useSnackbar from '@hook/useSnackbar'

interface IProps {
    id: string
    refreshCallback?: () => void
}

type CurrScreen = 'stop' | 'confirm'
type CloseAction = 'keep-position' | 'close-position'
const DeleteCopierModal = ({id, refreshCallback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const [closeAction, setCloseAction] = useState<CloseAction | undefined>(undefined)
    const [currentScreen, setCurrentScreen] = useState<CurrScreen>('stop')
    const {isRenderDark} = useDark()
    const [isLoading, setIsLoading] = useState(false)
    const {showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    const modalTitle = useMemo(() => {
        if (currentScreen === 'stop') return t('modal.stopCopier.title.stop')
        if (currentScreen === 'confirm') return t('modal.stopCopier.title.confirm')
    }, [currentScreen, t])

    const onStopBot = async () => {
        setIsLoading(true)
        const {data, error, status} = await apiPostPausedCopier(id, closeAction === 'close-position')
        if (data) {
            modalRef.current.close()
            onClose()
            refreshCallback && refreshCallback()
        } else if (error) {
            addErrorLog(ERROR_LOG.copyTrading.stopFail, {error})
            showErrorSnackbar(t('error.code.default'))
        }
        setIsLoading(false)
    }
    const onCancel = () => {
        modalRef.current.close()
        onClose()
    }

    const onClose = () => {
        setTimeout(() => {
            setCurrentScreen('stop')
            setCloseAction(undefined)
            setIsLoading(false)
        }, 300)
    }

    const closeButtonText = useMemo(() => {
        if (closeAction === 'keep-position') return t('modal.stopCopier.keepButton')
        return t('modal.stopCopier.closeButton')
    }, [closeAction, t])

    return (
        <WizContentModal
            title={modalTitle}
            ref={modalRef}
            onCloseCallback={onClose}
            size={currentScreen === 'stop' ? '600' : '340'}>
            {currentScreen === 'stop' && (
                <>
                    <Text className={'text-h3 text-center mt-4 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.stopCopier.subtitle')}
                    </Text>
                    <Text className={'text-center mt-4 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.stopCopier.smallInfo')}
                    </Text>

                    <div className={'flex items-center justify-center mt-4'}>
                        <WizImage
                            src={isRenderDark ? img_delete_copier_dark : img_delete_copier}
                            width={150}
                            height={150}
                        />
                    </div>

                    <Text className="text-ti1 mt-4 text-gray01 dark:text-dark_gray01">
                        {t('modal.stopCopier.selectTitle')}
                    </Text>
                    <div
                        className={
                            'border border-gray06 dark:border-dark_gray06 overflow-hidden rounded-lg mt-[10px] px-[20px] py-[15px] space-y-[20px]'
                        }>
                        <div
                            className={'flex cursor-pointer gap-x-[10px]'}
                            onClick={e => {
                                e.preventDefault()
                                setCloseAction('keep-position')
                            }}>
                            <WizRadioButton checked={closeAction === 'keep-position'} />
                            <div className={'flex-1'}>
                                <Text className="text-ti1 text-gray02 dark:text-dark_gray02">
                                    {t('modal.stopCopier.keepTitle')}
                                </Text>
                                <Text className="text-gray02 dark:text-dark_gray02">
                                    {t('modal.stopCopier.keepDescription')}
                                </Text>
                            </div>
                        </div>
                        <div
                            className={'flex cursor-pointer gap-x-[10px]'}
                            onClick={e => {
                                e.preventDefault()
                                setCloseAction('close-position')
                            }}>
                            <WizRadioButton checked={closeAction === 'close-position'} />
                            <div className={'flex-1'}>
                                <Text className="text-ti1 text-gray02 dark:text-dark_gray02">
                                    {t('modal.stopCopier.closeTitle')}
                                </Text>
                                <Text className="text-gray02 dark:text-dark_gray02">
                                    {t('modal.stopCopier.closeDescription')}
                                </Text>
                            </div>
                        </div>
                    </div>

                    <Text className="text-center mt-[10px] mb-[30px] text-body2 italic text-gray02 dark:text-dark_gray02">
                        {t('modal.stopCopier.invoiceNotice')}
                    </Text>

                    <div className={'grid grid-cols-2 gap-2 mt-4'}>
                        <WizButton text={t('modal.stopCopier.cancelButton')} buttonType={'border'} onClick={onCancel} />
                        <WizButton
                            text={closeButtonText}
                            buttonType={'red'}
                            disabled={!closeAction}
                            onClick={() => {
                                setCurrentScreen('confirm')
                            }}
                        />
                    </div>
                </>
            )}
            {currentScreen === 'confirm' && !isLoading && (
                <div>
                    {closeAction === 'keep-position' && (
                        <Text className={'mt-[10px] mb-[30px] text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.stopCopier.confirmWithKeep')}
                        </Text>
                    )}
                    {closeAction === 'close-position' && (
                        <Text className={'mt-[10px] mb-[30px] text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.stopCopier.confirmWithClose')}
                        </Text>
                    )}

                    <div className={'grid grid-cols-2 gap-2 mt-8'}>
                        <WizButton
                            text={t('modal.stopCopier.confirm.cancelButton')}
                            buttonType={'border'}
                            onClick={onCancel}
                        />
                        <WizButton
                            text={t('modal.stopCopier.confirm.confirmButton')}
                            buttonType={'red'}
                            onClick={onStopBot}
                        />
                    </div>
                </div>
            )}

            {currentScreen === 'confirm' && isLoading && (
                <div className={'w-[250px] px-8 py-16 flex flex-col items-center justify-center mx-auto'}>
                    <Spinner visible={true} size={'large'} />
                    <Text className={'mt-8 text-gray01 dark:text-dark_gray01'}>
                        {t('modal.stopCopier.confirm.stoppingStatus')}
                    </Text>
                </div>
            )}
        </WizContentModal>
    )
}

export default React.forwardRef(DeleteCopierModal)
